import React, {
  useState,
  useCallback,
  useRef,
  useMemo,
  useEffect,
} from "react";
import { useOutletContext } from "react-router-dom";
import {
  axiosTeilnehmRoute,
  axiosAemterRoute,
  axiosSeminarParticipantRoute,
  axiosInvoiceRoute,
  axiosWordPressSeminarRoute,
} from "../helpers/axios";
import { validateMe } from "../helpers/validation";
import EmployeeAddModal from "./EmployeeAddModal";
import EditIcon from "@mui/icons-material/Edit";
import ContentConpyIcon from "@mui/icons-material/ContentCopy";
import ButtonUnstyled from "@mui/base/ButtonUnstyled";

import { Checkbox, Switch, FormControlLabel } from "@mui/material";

import { AgGridReact } from "ag-grid-react";
import ClientModal from "./ClientModal";

import AG_GRID_LOCALE_DE from "./locale.de";
import { useLocation, useSearchParams } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";
import ClearButton from "@mui/icons-material/Clear";

import listofGermanStates from "./partials/listofGermanStates";

import IconButton from "@mui/material/IconButton";
import SearchButton from "@mui/icons-material/Search";
import LoadingButton from "@mui/lab/LoadingButton";

const Kundenverwaltung = ({
  mode,
  seminarId,
  setAddParticipant,
  setSwapParticipant,
  selectedParticipantInvoicesDetails,
}) => {
  const localeText = useMemo(() => {
    return AG_GRID_LOCALE_DE;
  }, []);

  const location = useLocation();

  const {
    token,
    notify,
    seminarInputs,
    getData,
    seminarParticipantsList,
    storedParticipantSelection,
    storedClientSelection,
    getClientData,
    getParticipantData,
    invoiceSelection,
    getInvoiceData,
    errorHandling,
    setErrorHandling,
    getStoredParticipantDetails,
    getStoredClientData,
    getSemPartData,
    WPToken,
    setTriggerSeminarSelect,
  } = useOutletContext();

  const [open, setOpen] = useState(false);

  const [query, setQuery] = useState("");

  const [clientQueryResults, setClientQueryResults] = useState([]);

  const [openClient, setOpenClient] = useState(false);

  const [genInvoice, setGenInvoice] = useState(true);

  const [genManualInvoice, setGenManualInvoice] = useState(false);

  const [clientInputs, setClientInputs] = useState({});

  const [employeeInputs, setEmployeeInputs] = useState({});

  const [formErrorHandling, setFormErrorHandling] = useState(null);
  const [initialLoad, setInitialLoad] = useState(true);

  const [loading, setLoading] = useState();

  const [land, setLand] = useState(null);

  const [loadingDyn, setLoadingDyn] = useState(false);
  const [suche, setSuche] = useState(false);

  const [searchParams] = useSearchParams();

  const [selectedParticipants, setSelectedParticipants] = useState([]);

  useEffect(() => {
    if (clientInputs?.id) {
      getStoredParticipantDetails(clientInputs.id);
    }
  }, [clientInputs.id, getStoredParticipantDetails]);

  useEffect(() => {
    if (location.pathname === "/kunden/verwaltung" && !suche) {
      if (seminarInputs?.id) {
        getStoredClientData({
          seminar_id: seminarInputs.id,
        });
      } else {
        getStoredClientData();
      }
    }
  }, [suche, getStoredClientData, location, seminarInputs]);

  const handleChange = (value, name, type) => {
    setClientInputs((values) => ({ ...values, [name]: value }));

    const valid = validateMe(type, value);

    setErrorHandling((prevState) => {
      return { ...prevState, [name]: valid };
    });
  };

  const handleSecondChange = (value, name, type) => {
    setEmployeeInputs((values) => ({ ...values, [name]: value }));

    const valid = validateMe(type, value);

    setErrorHandling((prevState) => {
      return { ...prevState, [name]: valid };
    });
  };

  const createInvoiceHandler = useCallback(
    async (params) => {
      const data = {
        seminarInputs,
        clientInputs: params.data,
      };

      // setLoading(true);

      try {
        let result;

        result = await axiosInvoiceRoute.post(
          `/`,
          {
            data: data,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // strip the id and wordpress metadata from the seminarInputs if user is copying a seminar

        if (result?.data) {
          notify("info", "Invoice Created");

          // setLoading(false);
          getData();
          setErrorHandling({});
          getInvoiceData();
        }
      } catch (err) {
        console.log(err);
        setFormErrorHandling(err.response.data.error.details.errors);
        // setLoading(false);
      }
    },
    [getData, getInvoiceData, notify, seminarInputs, token, setErrorHandling]
  );

  const checkForInvoiceInstance = (params) => {
    return invoiceSelection.some(
      (invoice) =>
        Number(invoice.attributes.id_amt) === Number(params.data.id) &&
        Number(invoice.attributes.id_seminar) === Number(seminarInputs.id)
    );
  };

  const columnClientDefs = useMemo(
    () => [
      {
        width: 30,
        cellStyle: { paddingLeft: 4, paddingRight: 4 },
        headerClass: "header-blue",
        suppressSizeToFit: true,
        cellRenderer: (params) => (
          <Checkbox
            size="small"
            className="flex h-full"
            checked={params.data.id === clientInputs.id ? true : false}
          />
        ),
      },
      {
        width: 150,
        headerClass: "header-blue",
        suppressSizeToFit: true,
        cellRenderer: (params) => (
          <button
            disabled={
              !checkForInvoiceInstance(params) &&
              clientInputs.id === params.data.id &&
              seminarInputs.id
                ? false
                : true
            }
            onClick={(e) => {
              e.preventDefault();
              createInvoiceHandler(params);
            }}
            className="disabled:opacity-25 disabled:pointer-events-none whitespace-nowrap inline-flex items-center px-2 py-1 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-900 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
          >
            {checkForInvoiceInstance(params) ? "Invoiced" : "Gen. Invoice"}
          </button>
        ),
        hide: mode !== "invoicing",
      },
      {
        field: "edit",
        headerName: "",
        width: 30,
        minWidth: 30,
        cellStyle: { padding: 4 },
        headerClass: "header-blue",
        resizable: false,

        cellRenderer: () => {
          return (
            <ButtonUnstyled
              onClick={() => clientInputs.id && setOpenClient("edit")}
              aria-label="edit"
              className={
                clientInputs.id
                  ? "opacity-100 flex h-full items-center"
                  : "opacity-30 cursor-default flex h-full items-center"
              }
            >
              <EditIcon fontSize="small" />
            </ButtonUnstyled>
          );
        },
        hide: location.pathname !== "/kunden/verwaltung",
      },
      {
        field: "copy",
        headerName: "",
        width: 30,
        minWidth: 30,
        cellStyle: { padding: 4 },
        headerClass: "header-blue",
        resizable: false,
        cellRenderer: () => {
          return (
            <ButtonUnstyled
              onClick={() => clientInputs.id && setOpenClient("copy")}
              aria-label="copy"
              className={
                clientInputs.id
                  ? "opacity-100 flex h-full items-center"
                  : "opacity-30 cursor-default flex h-full items-center"
              }
            >
              <ContentConpyIcon fontSize="small" />
            </ButtonUnstyled>
          );
        },
        hide: location.pathname !== "/kunden/verwaltung",
      },
      {
        field: "ort",
        headerName: "Ort",
        sortable: true,
        sort: "asc",
        width: 115,
        minWidth: 85,
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "participants",
        headerName: "Participants",
        sortable: true,

        width: 130,
        headerClass: "header-blue",
        resizable: true,
        hide: mode !== "invoicing",
      },
      {
        field: "bezeichnung",
        headerName: "Bezeichnung",
        sortable: true,

        width: 300,
        minWidth: 200,
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "bezeichnung2",
        width: 120,
        headerName: "Bezeichnung 2",
        sortable: true,
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "bundes_land_id",
        headerName: "Bundesland",
        sortable: true,
        width: 85,
        minWidth: 85,
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "plz",
        headerName: "Plz",
        sortable: true,

        width: 85,
        minWidth: 85,
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "strasse",
        headerName: "Straße",
        sortable: true,

        //width: 150,
        minWidth: 150,
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "haus_num",
        headerName: "Haus-Nr.",
        sortable: true,

        width: 100,
        minWidth: 100,
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "telefon1",
        headerName: "Telefon",
        sortable: true,

        //width: 150,
        minWidth: 100,
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "email",
        headerName: "E-Mail",
        sortable: true,

        //width: 200,
        minWidth: 200,
        headerClass: "header-blue",
        resizable: true,
      },
    ],
    // eslint-disable-next-line
    [
      clientInputs.id,
      location.pathname,
      mode,
      invoiceSelection,
      seminarInputs.id,
      createInvoiceHandler,
    ]
  );

  const [columnDefs] = useState([
    {
      checkboxSelection: true,
      minWidth: 50,
      maxWidth: 50,
      cellStyle: { pointerEvents: "none" },
      headerClass: "header-blue",
      resizable: true,
      headerCheckboxSelection: true,
      hide: mode !== "addnewparticipant",
    },
    {
      field: "edit",
      headerName: "",
      width: 30,
      minWidth: 30,
      cellStyle: { padding: 4 },
      headerClass: "header-blue",
      resizable: false,
      cellRenderer: () => (
        <EditIcon
          fontSize="small"
          className="cursor-pointer h-full flex"
          onClick={() => setOpen("edit")}
        />
      ),
      hide: location.pathname !== "/kunden/verwaltung",
    },
    {
      field: "copy",
      headerName: "",
      width: 30,
      minWidth: 30,
      cellStyle: { padding: 4 },
      headerClass: "header-blue",
      resizable: false,
      cellRenderer: () => (
        <ContentConpyIcon
          fontSize="small"
          className="cursor-pointer flex h-full"
          onClick={() => setOpen("copy")}
        />
      ),
      hide: location.pathname !== "/kunden/verwaltung",
    },
    {
      field: "name",
      headerName: "Name",
      sortable: true,
      sort: "asc",
      width: 100,
      cellStyle: { paddingLeft: 8 },
      headerClass: "header-blue",
      resizable: true,
    },
    {
      field: "vorname",
      headerName: "Vorname",
      sortable: true,
      width: 100,
      cellStyle: { paddingLeft: 8 },
      headerClass: "header-blue",
      resizable: true,
    },
    {
      field: "abteilung",
      headerName: "Abteilung",
      sortable: true,
      width: 100,
      cellStyle: { paddingLeft: 8 },
      headerClass: "header-blue",
      resizable: true,
    },
    {
      field: "telefon",
      headerName: "Telefon",
      sortable: true,
      width: 175,
      cellStyle: { paddingLeft: 8 },
      headerClass: "header-blue",
      resizable: true,
    },
    {
      field: "email",
      headerName: "E-Mail",
      sortable: true,
      width: 225,
      cellStyle: { paddingLeft: 8 },
      headerClass: "header-blue",
      resizable: true,
    },
  ]);

  const gridRef = useRef();
  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    const selectedEmploy = selectedRows.length === 1 ? selectedRows[0] : "";
    setEmployeeInputs(selectedEmploy);
  }, []);

  const gridRefClient = useRef();

  const onClientSelectionChanged = (event) => {
    if (mode === "invoicing") {
      const selectedRows = gridRefClient.current.api.getSelectedRows();
      const selectedClient = selectedRows.length === 1 ? selectedRows[0] : "";
      setClientInputs(selectedClient);
    } else {
      if (event.node.isSelected()) {
        if (
          event?.api?.getFocusedCell()?.column?.getColId() !== "copy" &&
          event?.api?.getFocusedCell()?.column?.getColId() !== "edit"
        ) {
          setClientInputs({});
        }
      } else {
        event.node.setSelected(true);
        setClientInputs(event.data);
        setQuery("");
        setLand(null);
      }
    }
  };

  const isExternalFilterPresent = useCallback(() => {
    return clientInputs.id !== undefined;
  }, [clientInputs.id]);

  useEffect(() => {
    if (mode !== "invoicing") {
      if (gridRefClient?.current?.api) {
        if (!clientInputs.id) {
          gridRefClient.current.api.deselectAll();
        }
        gridRefClient.current.api.onFilterChanged();
      }
    }
  }, [clientInputs.id, gridRefClient, mode]);

  const formClient = async (event, openClient) => {
    event.preventDefault();
    setLoading("loadingClient");
    try {
      let result;

      const data = {
        ...clientInputs,
      };

      if (openClient === "edit") {
        result = await axiosAemterRoute.put(
          "/" + clientInputs.id,
          {
            data: data,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      if (openClient === "add" || openClient === "copy") {
        const { id, ...noid } = clientInputs;

        result = await axiosAemterRoute.post(
          "/",
          {
            data: {
              ...(openClient === "copy" ? noid : clientInputs),
            },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
      if (result?.data) {
        notify(
          "info",
          openClient === "add" || openClient === "copy"
            ? "Erstellt Kunde"
            : "Aktualisiert Kunde"
        );

        if (result.data.note) {
          notify("info", result.data.note);
        }

        getStoredClientData({
          "filters[id]": result.data.data.id,
        });
        setOpenClient(false);
        setClientInputs({});
        setErrorHandling({});
        setLoading();
      }
    } catch (err) {
      console.log(err);
      setFormErrorHandling(err.response.data.error.details.errors);
      setLoading();
      notify("error", "Etwas ist schief gelaufen...");
    }
  };

  const formEmployee = async (event, open) => {
    event.preventDefault();
    setLoading("loadingEmployee");
    try {
      let result;

      const data = {
        ...employeeInputs,
      };

      if (open === "edit") {
        result = await axiosTeilnehmRoute.put(
          `/${employeeInputs.id}`,
          {
            data: data,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      if (open === "add" || open === "copy") {
        const { id, ...noid } = employeeInputs;
        result = await axiosTeilnehmRoute.post(
          "/",
          {
            data: {
              ...(open === "copy" ? noid : employeeInputs),
              id_amt: clientInputs.id,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
      if (result?.data) {
        setOpen(false);
        notify(
          "info",
          open === "add" || open === "copy"
            ? "Erstellt Mitarbeiter"
            : "Aktualisiert Mitarbeiter"
        );
        getParticipantData();
        getStoredParticipantDetails(clientInputs.id);
        setLoading();
        setEmployeeInputs({});
      }
    } catch (err) {
      console.log(err);
      setFormErrorHandling(err.response.data.error.details.errors);
      setLoading();
      notify("error", "Etwas ist schief gelaufen...");
    }
  };

  const formAddParticipant = async (event) => {
    event.preventDefault();
    setLoading("loadingAddEmployee");
    try {
      let result;

      result = await axiosSeminarParticipantRoute.post(
        "/",
        {
          data: {
            id_seminar: seminarId,
            id_teilnehmer: selectedParticipants,
            id_amt: clientInputs.id,
            status: "active",
            invoice_type: genManualInvoice === true ? "manual" : "auto",
            do_not_invoice: genInvoice === false && genManualInvoice === false,
            ...(mode === "swapparticipant" && {
              swap_participant: true,
              id_old_tn: selectedParticipantInvoicesDetails.id,
            }),
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (result.data.course_full) {
        const wptoken = WPToken;

        let formdata = new FormData();

        formdata.append("acf[is_booked_up]", "1");

        try {
          await axiosWordPressSeminarRoute.post(
            `/${result.data.id_wp_entry}`,
            formdata,
            {
              headers: {
                Authorization: `Bearer ${wptoken}`,
              },
            }
          );
        } catch (err) {
          notify("error", "Etwas ist schief gelaufen...");

          console.log(err);
        }
      }

      if (result?.data) {
        await getData();
        await getInvoiceData();
        await getSemPartData();
        setErrorHandling({});
        setLoading();
        notify(
          "info",
          (mode === "addnewparticipant" && "Erstellt Teilehmer") ||
            (mode === "swapparticipant" && "Teilnehmer Getauscht")
        );
      }
      setTriggerSeminarSelect(true);
      setAddParticipant(false);
      setSwapParticipant(false);
      setGenInvoice(true);
    } catch (err) {
      console.log(err);
      setLoading();
      setFormErrorHandling(err.response.data.error.details.errors);
      notify("error", "Etwas ist schief gelaufen...");
    }
  };

  useEffect(() => {
    const clientFileredLand =
      land === null
        ? storedClientSelection
        : storedClientSelection.filter(
            (client) => client.attributes.bundes_land_id === land
          );

    if (clientFileredLand?.length > 0) {
      setClientQueryResults(clientFileredLand);
    } else {
      setClientQueryResults([]);
    }

    // eslint-disable-next-line
  }, [query, storedClientSelection, land]);

  const seminarParticipantsFiltered = storedParticipantSelection.filter(
    (item) =>
      item.attributes.id_amt === String(clientInputs.id) &&
      !seminarParticipantsList.some(
        (sempart) =>
          sempart.attributes.id_seminar.data.id === seminarInputs.id &&
          sempart.attributes.id_teilnehmer.data.id === item.id &&
          sempart.attributes.status === "active"
      )
  );

  const filterHandler = async (e, action, filter = false) => {
    e.preventDefault();
    setLoadingDyn(action);
    // setSeminarInputs({});

    if (action === "suche") {
      await getStoredClientData(
        filter && {
          "filters[$or][0][bezeichnung][$containsi]": query.suche,
          "filters[$or][1][ort][$containsi]": query.suche,
        }
      );
      setSuche(filter);
    }

    if (filter === false) {
      setQuery({
        suche: "",
      });
    }

    setLoadingDyn(false);
  };

  const getRowId = useMemo(() => {
    return (params) => {
      return params.data.id;
    };
  }, []);

  return (
    <div>
      {mode === "addnewparticipant" ||
      mode === "invoicing" ||
      mode === "swapparticipant" ? (
        <div
          className={`w-full ${
            mode !== "invoicing" && "pb-3"
          }  text-sm text-left font-medium text-gray-700 flex justify-between`}
        >
          <h3>Wählen Sie Kunde aus</h3>
        </div>
      ) : (
        <div
          className={`sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow`}
        >
          <div className="flex flex-1 justify-between mx-6 mt-4 h-min">
            <h3>Kundenverwaltung</h3>

            <button
              onClick={() => {
                setOpenClient("add");
              }}
              className="addButton"
            >
              Hinzufügen
            </button>
          </div>
        </div>
      )}
      <div
        className={`w-full text-sm text-left font-medium text-gray-700 ${
          mode === "clientedit" && "px-6 py-5"
        }`}
      >
        <div
          className={`${
            mode === "swapparticipant" && "pointer-events-none opacity-25"
          } mb-3 flex w-full`}
        >
          <div className="mb-3 flex w-1/3">
            <div className="whitespace-nowrap flex items-center">Suche:</div>

            <div className="relative flex w-full">
              <input
                className="ml-5 h-min p-1 w-full border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
                variant="outlined"
                value={query.suche}
                onChange={(e) =>
                  setQuery((prior) => ({
                    ...prior,
                    suche: e.target.value,
                  }))
                }
                onKeyDown={(e) => {
                  if (e.key === "Enter" && query.suche) {
                    filterHandler(e, "suche", true);
                  }
                }}
              />

              <div className="absolute inset-y-0 right-0 flex items-center justify-center">
                <LoadingButton
                  sx={{ minWidth: "auto", padding: 0 }}
                  disableRipple
                  loading={loadingDyn === "suche"}
                  disabled={query?.suche ? false : true}
                  onClick={(e) => filterHandler(e, "suche", true)}
                >
                  {<SearchButton />}
                </LoadingButton>
                {suche && loadingDyn === false && (
                  <IconButton
                    color="warning"
                    sx={{ padding: 0 }}
                    disabled={loadingDyn === "suche" || clientInputs.id}
                    onClick={(e) => filterHandler(e, "suche", false)}
                    disableRipple
                  >
                    <ClearButton />
                  </IconButton>
                )}
              </div>
            </div>
          </div>

          <div className="mb-3 flex w-1/2">
            <span className="w-1/4 whitespace-nowrap flex items-center justify-end mr-2">
              Bundesland:
            </span>
            <div
              className={`w-2/4 p-1 outline-none sm:text-sm  border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0"
                    } `}
            >
              <select
                value={land || 0}
                onChange={(e) => {
                  setLand(e.target.value);
                  if (clientInputs.id) {
                    setClientInputs({});
                  }
                }}
                className="w-full background-transparant border-0 sm:text-sm focus-within:ring-0 p-0 bg-transparent"
              >
                <option value={0} disabled hidden>
                  Wählen Sie Ein Bundesland
                </option>
                {listofGermanStates.map((state) => (
                  <option value={state.province} key={state.id}>
                    {state.province}
                  </option>
                ))}
              </select>
            </div>
            {land && (
              <div className="flex items-center">
                <ClearButton
                  className="hover:text-red-500"
                  onClick={() => {
                    setLand(null);
                    setClientInputs({});
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <div className="grid grid-cols-6 gap-x-6 gap-y-4 w-full">
          <div
            style={
              mode !== "invoicing"
                ? clientInputs.id
                  ? { height: 152 }
                  : { height: 300 }
                : { height: 600 }
            }
            className="ag-theme-alpine w-full col-span-6"
          >
            <AgGridReact
              getRowId={getRowId}
              enableCellTextSelection
              rowHeight={33}
              defaultColDef={{
                filter: false,
              }}
              rowData={clientQueryResults
                .filter((filtclient) => {
                  if (mode === "invoicing") {
                    return seminarParticipantsList.some(
                      (sempart) =>
                        Number(
                          sempart.attributes.id_teilnehmer.data.attributes
                            .id_amt
                        ) === Number(filtclient?.item?.id) &&
                        Number(sempart.attributes.id_seminar.data.id) ===
                          Number(seminarId)
                    )
                      ? true
                      : false;
                  } else {
                    if (mode === "swapparticipant") {
                      return (
                        Number(filtclient?.id) ===
                        Number(
                          selectedParticipantInvoicesDetails?.attributes
                            ?.rechnung_id?.data?.attributes?.id_amt
                        )
                      );
                    } else {
                      return true;
                    }
                  }
                })
                .map((client) => ({
                  ...client.attributes,
                  id: client.id,
                  ...(mode === "invoicing" && {
                    participants: seminarParticipantsList.filter(
                      (sempart) =>
                        Number(
                          sempart.attributes.id_teilnehmer.data.attributes
                            .id_amt
                        ) === Number(client.item.id) &&
                        Number(sempart.attributes.id_seminar.data.id) ===
                          Number(seminarId)
                    ).length,
                  }),
                }))}
              columnDefs={columnClientDefs}
              rowSelection={"single"}
              onRowClicked={onClientSelectionChanged}
              ref={gridRefClient}
              // domLayout={"autoHeight"}
              localeText={localeText}
              onModelUpdated={(e) => {
                mode !== "invoicing" &&
                  e.api.forEachNode((node) => {
                    node.data.id === clientInputs.id &&
                      node.setSelected(true, true);
                  });
                if (searchParams.get("clientid") !== null && initialLoad) {
                  e.api.forEachNode((node) => {
                    if (
                      Number(node.data.id) ===
                      Number(searchParams.get("clientid"))
                    ) {
                      setClientInputs(node.data);
                      setInitialLoad(false);
                    }
                  });
                }
              }}
              // getRowClass={(params) => {
              //   //check if invoice already has been issued for the client.
              //   //If yes, block capacity to add new participants
              //   if (
              //     mode === "addnewparticipant" &&
              //     invoiceSelection.some(
              //       (invoice) =>
              //         Number(invoice.attributes.id_seminar) ===
              //           Number(seminarInputs.id) &&
              //         Number(invoice.attributes.id_amt) ===
              //           Number(params.node.data.id) &&
              //         invoice.attributes.status !== "offen"
              //     )
              //   ) {
              //     return ["opacity-25", "pointer-events-none"];
              //   }
              // }}
              paginationPageSize={50}
              pagination={true}
              isExternalFilterPresent={
                mode !== "invoicing" && isExternalFilterPresent
              }
              doesExternalFilterPass={(e) =>
                mode !== "invoicing" && e.data.id === clientInputs.id
              }
              suppressRowClickSelection={mode !== "invoicing"}
              onGridReady={(e) => {
                e.api.sizeColumnsToFit();
              }}
            ></AgGridReact>
          </div>
        </div>
        <form
          className={`${
            Object.keys(clientInputs).length === 0 &&
            "pointer-events-none opacity-25"
          } my-10`}
        >
          <div className="mb-10">
            <div className="bg-white">
              <div className="grid grid-cols-6 gap-x-6 gap-y-4 border-t border-lightgray">
                <div className="col-span-2 flex items-center mt-5">
                  {mode === "addnewparticipant" ? (
                    <h3>Teilnehmer auswählen</h3>
                  ) : null}
                </div>

                {mode !== "addnewparticipant" && mode !== "invoicing" && (
                  <>
                    <div className="col-span-2 flex items-center mt-5">
                      <h3>Mitarbeiter des Kunden</h3>
                    </div>
                    <div className="col-span-2 flex items-center justify-end mt-5">
                      <button
                        className="whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-900 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                        onClick={(e) => {
                          e.preventDefault();
                          setOpen("add");
                        }}
                      >
                        Hinzufügen
                      </button>
                    </div>
                  </>
                )}
                {mode !== "addnewparticipant" &&
                  mode !== "invoicing" &&
                  mode !== "swapparticipant" && (
                    <div className="col-span-2 w-full">
                      <div className="grid grid-cols-6 gap-x-6 gap-y-4">
                        <div className="col-span-6 flex items-center">
                          Bezeichnung:
                          <span className="font-bold ml-1">
                            {clientInputs.bezeichnung === null
                              ? ""
                              : clientInputs.bezeichnung}
                          </span>
                        </div>

                        <div className="col-span-6 whitespace-nowrap  flex items-center">
                          Bezeichnung 2:
                          <span className="font-bold ml-1">
                            {clientInputs.bezeichnung2 === null
                              ? ""
                              : clientInputs.bezeichnung2}
                          </span>
                        </div>

                        <div className="col-span-3 flex items-center">
                          PLZ:{" "}
                          <span className="font-bold ml-1">
                            {clientInputs.plz === null ? "" : clientInputs.plz}
                          </span>
                        </div>

                        <div className="col-span-3 flex items-center">
                          Ort:{" "}
                          <span className="font-bold ml-1">
                            {clientInputs.ort === null ? "" : clientInputs.ort}
                          </span>
                        </div>

                        <div className="col-span-6 flex items-center">
                          Straẞe:
                          <span className="font-bold ml-1">
                            {clientInputs.strasse === null
                              ? ""
                              : clientInputs.strasse}
                          </span>
                        </div>

                        <div className="col-span-6 flex items-center">
                          Haus-Nr.:{" "}
                          <span className="font-bold ml-1">
                            {clientInputs.haus_num === null
                              ? ""
                              : clientInputs.haus_num}
                          </span>
                        </div>

                        <div className="col-span-6 flex items-center">
                          Telefon:
                          <span className="font-bold ml-1">
                            {clientInputs.telefon1 === null
                              ? ""
                              : clientInputs.telefon1}
                          </span>
                        </div>

                        <div className="col-span-6 flex items-center">
                          Rechnungs-Email:
                          <span className="font-bold ml-1">
                            {clientInputs.email === null
                              ? ""
                              : clientInputs.email}
                          </span>
                        </div>

                        <div className="col-span-6 flex items-center">
                          Bundesland:
                          <span className="font-bold ml-1">
                            {clientInputs.bundes_land_id === null
                              ? ""
                              : clientInputs.bundes_land_id}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}

                {mode !== "invoicing" && (
                  <div
                    className={`ag-theme-alpine w-full ${
                      mode !== "addnewparticipant" && mode !== "swapparticipant"
                        ? "col-span-4"
                        : "col-span-6"
                    }`}
                    style={
                      mode !== "addnewparticipant" && mode !== "swapparticipant"
                        ? { height: 450 }
                        : clientInputs.id
                        ? { height: 400 }
                        : { height: 200 }
                    }
                  >
                    <AgGridReact
                      getRowId={getRowId}
                      enableCellTextSelection
                      defaultColDef={{
                        filter: false,
                      }}
                      rowHeight={33}
                      rowData={
                        location.pathname === "/kunden/verwaltung"
                          ? storedParticipantSelection
                              .filter(
                                (item) =>
                                  Number(item.attributes.id_amt) ===
                                  Number(clientInputs.id)
                              )
                              .map((employee) => ({
                                ...employee.attributes,
                                id: employee.id,
                              }))
                          : seminarParticipantsFiltered.map((employee) => ({
                              ...employee.attributes,
                              id: employee.id,
                            }))
                      }
                      columnDefs={columnDefs}
                      rowSelection={
                        mode === "addnewparticipant" ? "multiple" : "single"
                      }
                      rowMultiSelectWithClick={mode === "addnewparticipant"}
                      onRowClicked={onSelectionChanged}
                      ref={gridRef}
                      //domLayout={"autoHeight"}
                      localeText={localeText}
                      onGridSizeChanged={(e) => {
                        e.api.sizeColumnsToFit();
                      }}
                      onSelectionChanged={(event) => {
                        setSelectedParticipants(
                          event.api
                            .getSelectedNodes()
                            .map((node) => node.data.id)
                        );
                      }}
                    ></AgGridReact>
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
        {(mode === "addnewparticipant" || mode === "swapparticipant") && (
          <div className="flex w-full justify-end">
            {mode === "addnewparticipant" && (
              <>
                {!genInvoice && (
                  <>
                    <div className="flex items-center">Manuelle Rechnung</div>
                    <div className="w-25">
                      <Checkbox
                        disabled={!clientInputs.id}
                        onChange={() => setGenManualInvoice((prior) => !prior)}
                        checked={genManualInvoice}
                      />
                    </div>
                  </>
                )}

                <div className="flex w-52 justify-end">
                  <div className="flex items-center mr-4">
                    {genInvoice ? "Standard Rechnung" : "Ohne Rechnung"}
                  </div>
                  <FormControlLabel
                    control={
                      <Switch
                        disabled={!clientInputs.id}
                        onChange={() => {
                          setGenInvoice((prior) => !prior);
                          setGenManualInvoice(false);
                        }}
                        checked={genInvoice}
                      />
                    }
                    label=""
                  />
                </div>
              </>
            )}
            <button
              onClick={() => {
                setSwapParticipant(false);
                setAddParticipant(false);
              }}
              className="whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-900 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
            >
              Abbrechen
            </button>
            <button
              disabled={selectedParticipants.length === 0}
              onClick={(event) => {
                formAddParticipant(event);
              }}
              //className="disabled:opacity-40 disabled:pointer-events-none whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-900 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
              className={`${
                loading === "loadingAddEmployee"
                  ? "bg-white hover:bg-white disabled:ring-primary-500 disabled:ring-offset-2 disabled:ring-2"
                  : "bg-primary-900 disabled:opacity-40"
              } ml-5 relative whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500`}
            >
              Teilnehmer{" "}
              {mode === "swapparticipant" ? "austauschen" : "hinzufügen"}
              {loading === "loadingAddEmployee" && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </button>
          </div>
        )}
      </div>

      <ClientModal
        openClient={openClient}
        setOpenClient={setOpenClient}
        clientInputs={clientInputs}
        setClientInputs={setClientInputs}
        handleChange={handleChange}
        getClientData={getClientData}
        employeeSelection={storedParticipantSelection?.filter(
          (participant) =>
            Number(participant?.attributes?.id_amt) === Number(clientInputs?.id)
        )}
        storedParticipantSelection={storedParticipantSelection}
        listofGermanStates={listofGermanStates}
        formClient={formClient}
        setFormErrorHandling={setFormErrorHandling}
        formErrorHandling={formErrorHandling}
        loading={loading}
        errorHandling={errorHandling}
        setErrorHandling={setErrorHandling}
      />
      <EmployeeAddModal
        clientInputs={clientInputs}
        open={open}
        setOpen={setOpen}
        formEmployee={formEmployee}
        employeeInputs={employeeInputs}
        setEmployeeInputs={setEmployeeInputs}
        handleSecondChange={handleSecondChange}
        setFormErrorHandling={setFormErrorHandling}
        formErrorHandling={formErrorHandling}
        loading={loading}
        errorHandling={errorHandling}
        setErrorHandling={setErrorHandling}
      />
    </div>
  );
};

export default Kundenverwaltung;
